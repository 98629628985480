import { string } from 'prop-types';
import { Search } from '../../common/search';
import { Wrap } from '../../sections-wrap';
import * as styles from './index.module.scss';

function ErrorPage({ title }) {
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <main className={styles.dataContainer}>
          <section>
            <header>
              <h1 className={styles.title}>
                {title}
              </h1>
            </header>
            <div className={styles.content}>
              <p>It seems we can’t find what you’re looking for. Perhaps searching can help.</p>
              <Search className={styles.search} />
            </div>
          </section>
        </main>
      </Wrap>
    </div>
  );
}

ErrorPage.propTypes = {
  title: string,
};

ErrorPage.defaultProps = {
  title: 'Nothing found',
};

export { ErrorPage };
