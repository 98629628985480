import { string } from 'prop-types';
import { useState } from 'react';
import { classNames } from '../../../helpers';
import searchBlack from '../../../assets/icons/search-black.svg';

function Search({ className }) {
  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/search?value=${value}`;
  };
  return (
    <form className={classNames([className])} onSubmit={handleSubmit}>
      <label htmlFor="search">
        <input
          type="search"
          placeholder="Search…"
          id="search"
          onChange={(e) => setValue(e.target.value)}
        />
      </label>
      <button type="submit">
        <img src={searchBlack} alt="like" />
      </button>
    </form>
  );
}

Search.propTypes = {
  className: string,
};

Search.defaultProps = {
  className: '',
};

export { Search };
